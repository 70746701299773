<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch, nextTick, computed } from "vue";
import { useStore } from "vuex";

// Components
import ContactModal from "@/views/contacts/ContactModal.vue";

// Refs and State
const isModalVisible = ref(false);
const searchInputRef = ref<HTMLInputElement | null>(null);
const searchQuery = ref("");
const selectedContactIndex = ref(0);
const selectedContact = ref<Record<string, any> | null>(null);
const showContactModal = ref(false);

// Store
const store = useStore();
const contacts = computed(() => store.getters.getContacts);


// Computed for filtered contacts
const filteredContacts = computed(() => {
  if (!searchQuery.value) return [];
  return contacts.value.filter((contact: any) =>
    contact.companyName.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});

// Function to handle keyboard shortcuts
const handleKeyDown = (event: KeyboardEvent) => {
  if (event.ctrlKey && event.code === "Space") {
    event.preventDefault();
    isModalVisible.value = true;
  }

  if (event.code === "Escape" && isModalVisible.value) {
    closeModal();
  }

  if (event.code === "ArrowDown" && filteredContacts.value.length > 0) {
    event.preventDefault();
    selectedContactIndex.value =
      (selectedContactIndex.value + 1) % filteredContacts.value.length;
  }

  if (event.code === "ArrowUp" && filteredContacts.value.length > 0) {
    event.preventDefault();
    selectedContactIndex.value =
      (selectedContactIndex.value - 1 + filteredContacts.value.length) %
      filteredContacts.value.length;
  }

  if (event.code === "Enter" && isModalVisible.value) {
    const contact = filteredContacts.value[selectedContactIndex.value];
    if (contact) {
      openContactModal(contact);
    }
  }
};

// Open Contact Modal
const openContactModal = (contact: any) => {
  selectedContact.value = contact;
  setTimeout(() => {
    showContactModal.value = true; // Open modal after a short delay
  }, 100); // 100ms delay to avoid lingering keypress
};

// Close modal function
const closeModal = () => {
  isModalVisible.value = false;
};

// Watch `isModalVisible` to focus input when modal is shown
watch(isModalVisible, newValue => {
  if (newValue) {
    nextTick(() => searchInputRef.value?.focus());
  } else {
    searchQuery.value = ""; // Reset the search query when the modal is closed
    selectedContactIndex.value = 0; // Reset the selected contact index
  }
});

// Add event listener on mount and remove on unmount
onMounted(() => {
  window.addEventListener("keydown", handleKeyDown);
});
onUnmounted(() => {
  window.removeEventListener("keydown", handleKeyDown);
});

// Handlers for ContactModal events
const closeContactModal = () => {
  showContactModal.value = false;
  selectedContact.value = null;
  closeModal();
};

const deleteContact = async (uuid: string) => {
  await store.dispatch("deleteContact", uuid);
  closeContactModal();
};

const patchContact = async (updatedContact: any) => {
  await store.dispatch("patchContact", {
    uuid: updatedContact.uuid,
    patchData: updatedContact,
  });
};
</script>

<template>
  <!-- Global Shortcut Modal -->
  <div v-if="isModalVisible" class="modal-overlay" @click.self="closeModal">
    <div class="modal-container">
      <div class="modal-body">
        <!-- Input Field -->
        <input
          type="text"
          placeholder="Search..."
          class="search-input"
          v-model="searchQuery"
          ref="searchInputRef"
        />
        <!-- Filtered Contacts -->
        <ul v-if="searchQuery" class="contacts-list">
          <li
            v-for="(contact, index) in filteredContacts"
            :key="contact.uuid"
            :class="{ 'highlighted-contact': index === selectedContactIndex }"
          >
            {{ contact.companyName }}
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- Contact Modal -->
  <ContactModal
    v-if="showContactModal"
    :contact="selectedContact"
    :show="showContactModal"
    @close="closeContactModal"
    @delete="deleteContact"
    @patch="patchContact"
  />
</template>

<style scoped>
/* Modal overlay to dim the background */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal container for the content */
.modal-container {
  background-color: white;
  width: 90%;
  max-width: 600px;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

/* Modal body styles */
.modal-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Input styles */
.search-input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}
.search-input:focus {
  border-color: #ccc;
  box-shadow: none;
}

/* Contacts list */
.contacts-list {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
}

.contacts-list li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contacts-list li:hover,
.highlighted-contact {
  background-color: #f0f0f0;
  font-weight: bold;
}
</style>