// Interfaces
import { CoreMarketingRequestI } from '@/datastructures/interfaces/platforms/CoreMarketingRequestI';

export default {
  async fetchMarketingData({ state, dispatch }: { state: any; dispatch: any }, data: CoreMarketingRequestI) {
    const shouldFetch = (platform: string) => {
      const platformData = state.user.marketingData[platform];
      if (!platformData.lastFetchTime || !platformData.wasSuccessful) {
        return true;
      }
      return false;
    };

    // Check and dispatch for Meta if present
    if (data.meta && shouldFetch('meta')) {
      try {
        await dispatch('getAdAccountCampaigns', data.meta);
      } catch (error) {
        console.error('Error fetching Meta data:', error);
      }
    }

    // Check and dispatch for Google if present
    if (data.google) {
      try {
        await dispatch('fetchGoogleCampaignInsights', {
          // Add necessary Google parameters here
        });
      } catch (error) {
        console.error('Error fetching Google data:', error);
      }
    }

    // Add more checks for other sources as needed
    if (data.linkedin) {
      try {
        await dispatch('fetchLinkedInCampaignInsights', {
          // Add necessary LinkedIn parameters here
        });
      } catch (error) {
        console.error('Error fetching LinkedIn data:', error);
      }
    }
  },
};
