// Define the possible colors for fetch status
type FetchStatus = 'green' | 'yellow' | 'red';

// Define the structure of the platform data
interface PlatformData {
  lastFetchTime: string | null; // ISO timestamp of the last fetch attempt
  wasSuccessful: boolean; // Whether the last fetch attempt was successful
}

// Define the state structure
interface MarketingData {
  meta: PlatformData;
  google: PlatformData;
  linkedin: PlatformData;
  [key: string]: PlatformData; // Allow for additional dynamic data sources
}

interface RootState {
  user: {
    marketingData: MarketingData;
  };
}

// Define the return type of the getter
type FetchStatusResult = Record<string, FetchStatus>;

export default {
  fetchMarketingDataStatus: (state: RootState): FetchStatusResult => {
    const dataSources = ['meta', 'google', 'linkedin'];
    const currentTime = new Date();

    return dataSources.reduce<FetchStatusResult>((acc, source) => {
      const platformData = state.user.marketingData[source];
      if (!platformData) return acc;

      const { lastFetchTime, wasSuccessful } = platformData;

      if (!lastFetchTime || !wasSuccessful) {
        acc[source] = 'red'; // Red if no fetch or unsuccessful
      } else {
        const lastFetchDate = new Date(lastFetchTime);
        const hoursSinceFetch = (currentTime.getTime() - lastFetchDate.getTime()) / (1000 * 60 * 60); // Difference in hours

        if (hoursSinceFetch <= 1) {
          acc[source] = 'green'; // Green if fetched within the last hour
        } else if (hoursSinceFetch <= 2) {
          acc[source] = 'yellow'; // Yellow if fetched within the last two hours
        } else {
          acc[source] = 'red'; // Red if fetched more than three hours ago
        }
      }
      return acc;
    }, {});
  },
};
