import axios from '../../axios-auth';
import router from '../../../router';

const routePath = '/ai';

interface OpenAIResponse {
  data: string; // Adjust the type if the structure of `data` is different
}

export default {
  async callOpenAI({ commit }: { commit: Function }, { prompt }: { prompt: string }): Promise<string> {
    try {
      const response = await axios.post<OpenAIResponse>(`${routePath}/callOpenAI`, { prompt });
      return response.data.data; // Assuming the response data is under 'data'
    } catch (error) {
      console.error('Error calling OpenAI:', error);
      throw error;
    }
  },

  async callopenAILargeCall({ commit }: { commit: Function }, data: { prompt: string; language: string }): Promise<string> {
    try {
      console.log('--- callopenAILargeCall --- ');
      console.log('Data being sent to the backend:', data);

      const response = await axios.post<OpenAIResponse>(`${routePath}/callopenAILargeCall`, data);

      console.log('response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error calling OpenAI:', error);
      throw error;
    }
  },
};
