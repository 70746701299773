// Actions
import metaUserActions from './metaUserActions.js';
import adAccountActions from './adAccountActions';
import adCampaignActions from './adCampaignActions';
import facebookActions from './facebook/facebookActions';
import instagramActions from './instagram/instagramActions.js';

export default {
  ...metaUserActions,
  ...adAccountActions,
  ...adCampaignActions,
  ...facebookActions,
  ...instagramActions,
}
