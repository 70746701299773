<script setup>
import { computed } from "vue";
import { useLayout } from "@/layout/composables/layout";
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useLocaleMessages } from '@/utils/locales/useLocaleMessages';

// Store
import { useStore } from "vuex";
const store = useStore();
const { t } = useI18n();

useLocaleMessages({
  da: () => import('@/locales/da/layout/appProfileSidebar.json'),
  en: () => import('@/locales/en/layout/appProfileSidebar.json'),
});

const { onProfileSidebarToggle } = useLayout();

const router = useRouter();
const navigateToPage = () => {
  onProfileSidebarToggle();
  router.push('/profile');
};

const navigateToBusinessParnersView = () => {
  onProfileSidebarToggle();
  router.push('/business-partners');
};
const navigateToCustomerNotesView = () => {
  onProfileSidebarToggle();
  router.push('/contacts');
};
const navigateToReportsView = () => {
  onProfileSidebarToggle();
  router.push('/reports');
};

const showProfileSidebar = () => {
  onProfileSidebarToggle();
};

const user = computed(() => {
  if (store.state.user && store.state.user !== null) {
    return store.state.user;
  } else {
    return 'Loading...';
  }
});

const companyType = computed(() => {
  switch (user.value.userType) {
    case 'business':
      return t('companyType.business');
    case 'agency':
      return t('companyType.agency');
    case 'personal':
      return 'Personlig';
    default:
      return t('companyType.unknown');
  }
})

const logout = () => {
  store.dispatch("signout");
};

const { layoutState } = useLayout();
</script>

<template>
  <Sidebar v-model:visible="layoutState.profileSidebarVisible.value" position="right"
    class="layout-profile-sidebar w-full sm:w-25rem">
    <div class="flex flex-column mx-auto md:mx-0">
      <span class="mb-2 font-semibold">{{ user.companyName }}</span>
      <span class="text-color-secondary font-medium mb-5">{{ companyType }}</span>

      <ul class="list-none m-0 p-0">
        <li @click="navigateToPage">
          <a
            class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
            <span>
              <i class="pi pi-user text-xl text-primary"></i>
            </span>
            <div class="ml-3">
              <span class="mb-2 font-semibold">{{ t('profile.title') }}</span>
              <!-- <p class="text-color-secondary m-0">Lorem ipsum date visale</p> -->
            </div>
          </a>
        </li>
        <li @click="navigateToBusinessParnersView">
          <a
            class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
            <span>
              <i class="pi pi-users text-xl text-primary"></i>
            </span>
            <div class="ml-3">
              <span v-if="user.userType === 'agency'" class="mb-2 font-semibold">{{ t('profile.customers') }}</span>
              <span v-else class="mb-2 font-semibold">{{ t('profile.partners') }}</span>
              <!-- <p class="text-color-secondary m-0">Amet mimin mıollit</p> -->
            </div>
          </a>
        </li>
        <li v-if="user.userType === 'agency'" @click="navigateToCustomerNotesView">
          <a
            class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
            <span>
              <i class="pi pi-list text-xl text-primary"></i>
            </span>
            <div class="ml-3">
              <span class="mb-2 font-semibold">{{ t('profile.customerNotes') }}</span>
            </div>
          </a>
        </li>
        <li v-if="user.userType === 'agency'" @click="navigateToReportsView">
          <a
            class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
            <span>
              <i class="pi pi-file text-xl text-primary"></i>
            </span>
            <div class="ml-3">
              <span class="mb-2 font-semibold">{{ t('profile.reports') }}</span>
            </div>
          </a>
        </li>
        <li>
          <a @click="logout"
            class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
            <span>
              <i class="pi pi-power-off text-xl text-primary"></i>
            </span>
            <div class="ml-3">
              <span class="mb-2 font-semibold">{{ t('profile.logout') }}</span>
              <!-- <p class="text-color-secondary m-0">Sed ut perspiciatis</p> -->
            </div>
          </a>
        </li>
      </ul>
    </div>

    <!-- <div class="flex flex-column mt-5 mx-auto md:mx-0">
            <span class="mb-2 font-semibold">Notifications</span>
            <span class="text-color-secondary font-medium mb-5">You have 3 notifications</span>

            <ul class="list-none m-0 p-0">
                <li>
                    <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                        <span>
                            <i class="pi pi-comment text-xl text-primary"></i>
                        </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold">Your post has new comments</span>
                            <p class="text-color-secondary m-0">5 min ago</p>
                        </div>
                    </a>
                </li>
                <li>
                    <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                        <span>
                            <i class="pi pi-trash text-xl text-primary"></i>
                        </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold">Your post has been deleted</span>
                            <p class="text-color-secondary m-0">15min ago</p>
                        </div>
                    </a>
                </li>
                <li>
                    <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                        <span>
                            <i class="pi pi-folder text-xl text-primary"></i>
                        </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold">Post has been updated</span>
                            <p class="text-color-secondary m-0">3h ago</p>
                        </div>
                    </a>
                </li>
            </ul>
        </div> -->

    <!-- <div class="flex flex-column mt-5 mx-auto md:mx-0">
            <span class="mb-2 font-semibold">Messages</span>
            <span class="text-color-secondary font-medium mb-5">You have new messages</span>

            <ul class="list-none m-0 p-0">
                <li>
                    <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                        <span> <img src="/demo/images/avatar/circle/avatar-m-8.png" alt="Avatar" class="w-2rem h-2rem" /> </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold">James Robinson</span>
                            <p class="text-color-secondary m-0">10 min ago</p>
                        </div>
                        <Badge value="3" class="ml-auto"></Badge>
                    </a>
                </li>
                <li>
                    <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                        <span> <img src="/demo/images/avatar/circle/avatar-f-8.png" alt="Avatar" class="w-2rem h-2rem" /> </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold">Mary Watson</span>
                            <p class="text-color-secondary m-0">15min ago</p>
                        </div>
                        <Badge value="1" class="ml-auto"></Badge>
                    </a>
                </li>
                <li>
                    <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                        <span> <img src="/demo/images/avatar/circle/avatar-f-4.png" alt="Avatar" class="w-2rem h-2rem" /> </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold">Aisha Webb</span>
                            <p class="text-color-secondary m-0">3h ago</p>
                        </div>
                        <Badge value="2" class="ml-auto"></Badge>
                    </a>
                </li>
            </ul>
        </div> -->
  </Sidebar>
</template>
