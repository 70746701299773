<script setup lang="ts">
import { ref, watch, defineAsyncComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useLocaleMessages } from "@/utils/locales/useLocaleMessages";

import MMCheckbox from '@/components/form/MM-Checkbox.vue';

// Translations
const { t } = useI18n();
useLocaleMessages({
    da: () => import("@/locales/da/views/contacts/components/contactReminderView.json"),
    en: () => import("@/locales/en/views/contacts/components/contactReminderView.json"),
});

// Props
const props = defineProps({
    toggles: Object,
    editing: Boolean,
});

// Emits
const emit = defineEmits(["update:contact"]);

// Refs
const enableMidMonthCheckupReminder = ref(false);

// Watchers
watch(
    () => props.toggles?.enableMidMonthCheckupReminder,
    (newVal) => {
        enableMidMonthCheckupReminder.value = newVal === true; // Always set a boolean
    },
    { immediate: true }
);

watch(enableMidMonthCheckupReminder, () => {
    toggleReminder(); // Trigger the function whenever the value changes
});

// Functions
const toggleReminder = () => {
    const updatedToggles = {
        ...props.toggles,
        enableMidMonthCheckupReminder: enableMidMonthCheckupReminder.value, // Set a simple boolean
    };

    emit("update:contact", { toggles: updatedToggles }); // Emit updated toggles to parent
};

</script>

<template>
    <section class="modal-information-section">
        <div class="grid">
            <div class="col-12">
                <div class="flex align-items-center">
                    <MMCheckbox v-model="enableMidMonthCheckupReminder" id="enableMidMonthCheckupReminder"
                        :label="t('contactReminderView.toggleHalfMonthCheckReminder.title')" />
                    <label for="enableMidMonthCheckupReminder" class="ml-2">
                    </label>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
.modal-information-section {
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding: 0 2rem;
}
</style>