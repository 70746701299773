// Service
import { LocalStorageService } from '@/service/localStorage/LocalStorageService';
// Initialize
const localStorageService = new LocalStorageService();

// Interfaces
import { CoreMarketingRequestI } from '@/datastructures/interfaces/platforms/CoreMarketingRequestI';

import { format } from 'date-fns';

// Utils
import { getPastDays } from '@/utils/marketing/timeRangeUtil';

export async function fetchMetaAdCampaignsForAdAccount(store: any) {
  const selectedAdAccount: any = ensureSelectedAdAccount(store);
  const selectedTimePeriod: any = localStorageService.getItemAsJSON('mm-selectedTimePeriod');

  if (!selectedAdAccount) {
    console.error('No selected ad account found');
    return;
  }
  store.commit('storeSelectedAdAccount', selectedAdAccount.id);

  let insightParams;
  if (selectedTimePeriod.value === 'maximum') {
    insightParams = {
      id: selectedAdAccount.id,
      date_preset: 'maximum',
      time_range: null,
      time_increment: 1,
      fields: 'objective,impressions,cpc,cost_per_unique_click,frequency,reach,spend,actions',
    };
  } else {
    insightParams = {
      id: selectedAdAccount.id,
      date_preset: null,
      time_range: getPastDays(selectedTimePeriod.days),
      time_increment: 1,
      fields: 'objective,impressions,cpc,cost_per_unique_click,frequency,reach,spend,actions',
    };
  }

  const coreMarketingRequest: CoreMarketingRequestI = {
    meta: {
      lastSelectedAdAccountID: selectedAdAccount.id,
      insightParams,
      selectedTimePeriod,
    },
  };

  // Dispatch the action to fetch ad account campaigns and await completion
  try {
    await store.dispatch('fetchMarketingData', coreMarketingRequest);
  } catch (error) {
    console.error('Error fetching ad account campaigns:', error);
    throw error;
  }
}

function ensureSelectedAdAccount(store: any): any {
  let selectedAdAccount = localStorageService.getItemAsJSON('meta-selectedAdAccount');

  if (!selectedAdAccount) {
    const adAccounts: Array<Object> = store.state.user.marketingData?.meta?.adAccountsObject?.adAccounts;
    if (Array.isArray(adAccounts) && adAccounts.length > 0) {
      selectedAdAccount = adAccounts[0];
      localStorageService.setItemAsJSON('meta-selectedAdAccount', selectedAdAccount);
    } else {
      console.error('No ad accounts available in the store.');
      return null;
    }
  }

  return selectedAdAccount;
}

export function transformTimeRange(dates: string[]): string | null {
  if (dates.length === 2) {
      const [since, until] = dates.map(date => format(new Date(date), 'yyyy-MM-dd'));
      return JSON.stringify({ since, until });
  }
  return null;
}