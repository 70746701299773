<script setup lang="ts">
import { ref, defineExpose } from "vue";

const isLoading = ref(false);
const indicatorColor = ref("#22c55e"); // Default to green

// Ref for the button DOM element
const buttonRef = ref(null);

const handleButtonClick = () => {
  if (isLoading.value) return; // Prevent multiple presses
  isLoading.value = true;

  // Simulate color change logic
  setTimeout(() => {
    indicatorColor.value = "yellow";
  }, 1000);

  // Simulate a delay for the loading state
  setTimeout(() => {
    isLoading.value = false;
    indicatorColor.value = "red";
    console.log("Button action completed!");
  }, 2000);
};

// Expose the button DOM element to the parent
defineExpose({
  buttonRef,
});
</script>

<template>
  <div class="button-container">
    <span class="hover-text">Refresh</span>
    <button ref="buttonRef" class="loading-button" @click="handleButtonClick">
      <svg
        v-if="!isLoading"
        xmlns="http://www.w3.org/2000/svg"
        class="arrow-icon"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#4a4a4a"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M21 12a9 9 0 1 1-6.219-8.437" />
        <polyline points="21 3 21 9 15 9" />
      </svg>
      <div v-else class="spinner"></div>
    </button>
  </div>
</template>

<style scoped>
/* Container for the button and hover text */
.button-container {
  position: relative;
  display: inline-flex;
  align-items: center;
}

/* Hover text styling */
.hover-text {
  position: absolute;
  left: -70px;
  /* Position text to the left of the button */
  opacity: 0;
  color: var(--gray-500);
  font-size: 0.9rem;
  font-weight: bold;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateX(-10px);
  /* Slightly move text left initially */
  pointer-events: none;
  /* Prevent interaction with text */
}

/* Show text on hover */
.button-container:hover .hover-text {
  opacity: 1;
  transform: translateX(0);
  /* Move text back into position */
}

/* Loading button styling */
.loading-button {
  background-color: #eff3f8;
  color: white;
  font-size: 0.6rem;
  font-weight: bold;
  width: 35px;
  height: 35px;
  border: none;
  border-radius: 50%;
  /* Makes the button circular */
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  overflow: hidden;
}

.loading-button:hover {
  background-color: var(--gray-300);
}

/* Light Indicator Styling */
.light-indicator {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  /* Circular shape */
  background-color: var(--green-300);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

/* Arrow Icon */
.arrow-icon {
  width: 18px;
  height: 18px;
}

/* Spinner styling */
.spinner {
  width: 18px;
  height: 18px;
  border: 2px solid var(--gray-300);
  /* Gray spinner */
  border-top: 2px solid gray;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
