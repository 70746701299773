<template>
  <div class="tooltip-wrapper" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
    <slot />
    <div v-if="isVisible" class="tooltip" :style="tooltipStyle">
      <div class="tooltip-content">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

// Props to configure the delay
const props = defineProps<{
  delay?: number; // Delay in milliseconds (default: 2000ms)
}>();

const isVisible = ref(false);
const tooltipStyle = ref({}); // Tooltip position styles
let timeoutId: number | null = null; // To store the timeout ID
let hoveredElement: HTMLElement | null = null; // To store the hovered element

function handleMouseEnter(event: MouseEvent) {
  hoveredElement = event.currentTarget as HTMLElement;

  timeoutId = window.setTimeout(() => {
    if (hoveredElement) {
      const rect = hoveredElement.getBoundingClientRect();
      tooltipStyle.value = calculatePosition(rect);
      isVisible.value = true;
    }
  }, props.delay || 2000);
}

function handleMouseLeave() {
  if (timeoutId) {
    clearTimeout(timeoutId);
    timeoutId = null;
  }
  isVisible.value = false;
  hoveredElement = null;
}

function calculatePosition(rect: DOMRect) {
  const tooltipOffset = 8;
  return {
    top: `${rect.bottom + tooltipOffset}px`,
    left: `${rect.left}px`,
  };
}
</script>

<style>
.tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: fixed;
  background: white;
  color: black;
  padding: 16px; /* Increase padding inside the tooltip for more spacious content */
  border-radius: 6px; /* Rounded corners */
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Optional shadow */
}

.tooltip-content {
  display: flex;
  flex-direction: column; /* Stack rows vertically */
  gap: 12px; /* Increase space between rows */
}

.tooltip-row {
  display: flex;
  align-items: center; /* Align the dot and text vertically */
  gap: 8px; /* Space between the dot and text */
  margin-bottom: 8px; /* Optional: Additional space below each row */
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%; /* Makes the dot round */
}

.dot-green {
  background-color: var(--green-500);
}

.dot-yellow {
  background-color: var(--yellow-500);
}

.dot-red {
  background-color: var(--red-500);
}
</style>