import axios from '../../axios-auth';

// Interfaces
import { IBusinessPartner }  from '@/datastructures/interfaces/users/IBusinessPartner';

export default {
  applyForPartnership({ commit, state }, userData) {
    axios
      .put(`/user/appypartnership/${userData.email}`, userData)
      .then((res) => {
        commit('applyForPartnershipStatus', {
          status: 200,
          message: res.data.message,
        });
        const partnershipRequest = res.data.partnershipRequest
        commit('addBusinessPartnerRequest', partnershipRequest);
      })
      .catch((error) => {
        commit('applyForPartnershipStatus', {
          status: error.response.status,
          message: error.response.statusText,
        });
      });
  },
  
  applyForPartnershipStatusReset({ commit }) {
    commit('applyForPartnershipStatusReset');
  },

  removePartnershipRequest({ commit, state }, partnershipResponse) {
    console.log('Sending partnershipResponse:', partnershipResponse);
    axios
      .post(`/user/removePartnershipRequest`, partnershipResponse)
      .then((res) => {
        commit('removeBusinessPartnerRequest', partnershipResponse.receivingUserUUID);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  acceptPartnershipRequest({ commit, state, dispatch }, businessPartnerResponse) {
    axios
      .post(`/user/acceptPartnership`, businessPartnerResponse)
      .then((res) => {
        console.log('Accept partnership response:', res.data);

        console.log("userUUID");
        console.log(state.user.uuid);

        console.log("requestingUserUUID");
        console.log(res.data.requestingUser.uuid);

        console.log("res.data.receivingUser.business_partners[0]");
        console.log(res.data.receivingUser.business_partners[0]);

        console.log("res.data.requestingUser.business_partners[0]");
        console.log(res.data.requestingUser.business_partners[0]);

        const businessPartner: IBusinessPartner = {
          userUUID: res.data.receivingUser.uuid,
          profileImageUrl: res.data.receivingUser.profile.image.url,
          companyName: res.data.receivingUser.companyName,
          email: res.data.receivingUser.email,
        }

        console.log("businessPartner");
        console.log(businessPartner);

        if (state.user.uuid == res.data.requestingUser.uuid) {
          console.log("IS THE SAME")
          commit('addBusinessPartner', businessPartner);
        } else {
          console.log("IS NOT THE SAME")
          commit('addBusinessPartner', res.data.requestingUser.business_partners[0]);
        }

        dispatch('removePartnershipRequest', { ...businessPartnerResponse, skipNotification: true });
    })
      .catch((error) => {
        console.log(error);
      });
  },

  removePartnership({ commit, state }, partnershipData) {
    console.log('Removing partnership:', partnershipData);
    axios
      .post(`/user/removePartnership`, partnershipData)
      .then((res) => {
        console.log('Remove partnership response:', res.data);

        localStorage.removeItem('businessDataOnBehalfOf_UUID');
        localStorage.removeItem('businessDataOnBehalfOf_Name');
        localStorage.removeItem('businessDataOnBehalfOf_Email');

        console.log('Removing business partner:', partnershipData);
        console.log(res.data);

        // Check if the user type is business or agency (depending on how your logic defines user roles)
        if (state.user.uuid === res.data.requestingUser.uuid) {
          console.log('Removing business partner:', res.data.receivingUser.uuid);
          // If the current user initiated the partnership removal, remove the receiving user
          commit('removeBusinessPartner', res.data.receivingUser.uuid);
        } else {
          console.log('Removing business partner:', res.data.requestingUser.uuid);
          // Otherwise, remove the requesting user
          commit('removeBusinessPartner', res.data.requestingUser.uuid);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
