export function getPastDays(numberOfDays) {
    const today = new Date();
    const daysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - (numberOfDays - 1));

    // Format dates to "YYYY-MM-DD"
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (`0${date.getMonth() + 1}`).slice(-2); // getMonth() is zero-based
        const day = (`0${date.getDate()}`).slice(-2);
        return `${year}-${month}-${day}`;
    };

    return `{"since": "${formatDate(daysAgo)}", "until": "${formatDate(today)}"}`;
}