// Utility
import {
    calculateTotalSpend,
    calculatePriorTotalSpend,
    calculateTotalSpendByDate,
    calculateTotalSpendInWeeks,
    calculateTotalSpendInMonths,
    calculateTotalSpendInYears
} from '@/utils/marketing/meta/data/campaigns/spendProcessor';

import {
    calculateTotalClicksOnAd,
    calculatePriorTotalClicksOnAd,
    calculateTotalClicksOnAdTrendPercentage,
    calculateTotalClicksOnAdByDate,
    calculateTotalClicksOnAdInWeeks,
    calculateTotalClicksOnAdInMonths,
    calculateTotalClicksOnAdInYears
} from '@/utils/marketing/meta/data/campaigns/linkClickProcessor';

import {
    calculateTotalAverageCPC,
    calculatePriorAverageCPC,
    calculateTotalAverageCPCTrendPercentage,
    calculateTotalAverageCPCInDays,
    calculateTotalAverageCPCInWeeks,
    calculateTotalAverageCPCInMonths,
    calculateTotalAverageCPCInYears
} from '@/utils/marketing/meta/data/campaigns/cpcProcessor';

// TODO: Outsource to backend
import { calculateTotalImpressions, calculatePriorTotalImpressions, calculateTotalImpressionsTrendPercentage, calculateTotalImpressionsByDate } from '@/utils/marketing/meta/data/campaigns/impressionsProcessor';
import { calculateTotalReach, calculatePriorTotalReach, calculateTotalReachTrendPercentage, calculateTotalReachByDate } from '@/utils/marketing/meta/data/campaigns/reachProcessor';
import { calculateTotalAverageFrequency, calculatePriorAverageFrequency, calculateTotalAverageFrequencyTrendPercentage, calculateTotalAverageFrequencyArray } from '@/utils/marketing/meta/data/campaigns/frequencyProcessor';
import { calculateTotalPageEngagement, calculatePriorTotalPageEngagement, calculateTotalPageEngagementTrendPercentage, calculateTotalPageEngagementArray } from '@/utils/marketing/meta/data/campaigns/pageEngagementProcessor';
import { calculateTotalPostReactions, calculatePriorTotalPostReactions, calculateTotalPostReactionsTrendPercentage, calculateTotalPostReactionsArray } from '@/utils/marketing/meta/data/campaigns/postReactionsProcessor';

export default {
    storeMetaCampaigns(state, campaigns) {
        state.user.marketingData.meta.campaigns.campaignList = campaigns
    },

    storeSelectedAdCampaign(state, campaign) {
        state.user.marketingData.meta.selectedAdCampaign = campaign
    },

    resetAdCampaignsObjectDetailed(state) {
        state.user.marketingData.meta.campaigns.adCampaignsDetailed = [];
    },

    calculateAndStoreTotalSpend(state, campaigns) {
        const selectedTimePeriod = JSON.parse(localStorage.getItem('mm-selectedTimePeriod'));

        state.user.marketingData.meta.campaigns.generalMetrics.spend.totalSpend = calculateTotalSpend(campaigns, selectedTimePeriod);
        state.user.marketingData.meta.campaigns.generalMetrics.spend.priorTotalSpend = calculatePriorTotalSpend(campaigns, selectedTimePeriod);
        state.user.marketingData.meta.campaigns.generalMetrics.spend.totalSpendArray = calculateTotalSpendByDate(campaigns);
        state.user.marketingData.meta.campaigns.generalMetrics.spend.totalSpendInWeeks = calculateTotalSpendInWeeks(campaigns);
        state.user.marketingData.meta.campaigns.generalMetrics.spend.totalSpendInMonths = calculateTotalSpendInMonths(campaigns);
        state.user.marketingData.meta.campaigns.generalMetrics.spend.totalSpendInYears = calculateTotalSpendInYears(campaigns);
    },

    updateAdCampaignClickMetrics(state, campaigns) {
        const selectedTimePeriod = JSON.parse(localStorage.getItem('mm-selectedTimePeriod'));

        state.user.marketingData.meta.campaigns.generalMetrics.clicksOnAd.totalClicksOnAd = calculateTotalClicksOnAd(campaigns, selectedTimePeriod);
        state.user.marketingData.meta.campaigns.generalMetrics.clicksOnAd.priorTotalClicksOnAd = calculatePriorTotalClicksOnAd(campaigns, selectedTimePeriod);
        state.user.marketingData.meta.campaigns.generalMetrics.clicksOnAd.totalClicksOnAdTrendPercentage = calculateTotalClicksOnAdTrendPercentage(campaigns, selectedTimePeriod);
        state.user.marketingData.meta.campaigns.generalMetrics.clicksOnAd.totalClicksOnAdDays = calculateTotalClicksOnAdByDate(campaigns);
        state.user.marketingData.meta.campaigns.generalMetrics.clicksOnAd.totalClicksOnAdInWeeks = calculateTotalClicksOnAdInWeeks(campaigns);
        state.user.marketingData.meta.campaigns.generalMetrics.clicksOnAd.totalClicksOnAdInMonths = calculateTotalClicksOnAdInMonths(campaigns);
        state.user.marketingData.meta.campaigns.generalMetrics.clicksOnAd.totalClicksOnAdInYears = calculateTotalClicksOnAdInYears(campaigns);
    },

    updateAdCampaignCPCMetrics(state, campaigns) {
        const selectedTimePeriod = JSON.parse(localStorage.getItem('mm-selectedTimePeriod'));

        state.user.marketingData.meta.campaigns.generalMetrics.cost_per_unique_click.totalAverageCPC = calculateTotalAverageCPC(campaigns, selectedTimePeriod);
        state.user.marketingData.meta.campaigns.generalMetrics.cost_per_unique_click.priorAverageCPC = calculatePriorAverageCPC(campaigns, selectedTimePeriod);
        state.user.marketingData.meta.campaigns.generalMetrics.cost_per_unique_click.totalAverageCPCTrendPercentage = calculateTotalAverageCPCTrendPercentage(campaigns, selectedTimePeriod);
        state.user.marketingData.meta.campaigns.generalMetrics.cost_per_unique_click.totalDataInDays = calculateTotalAverageCPCInDays(campaigns);
        state.user.marketingData.meta.campaigns.generalMetrics.cost_per_unique_click.totalDataInWeeks = calculateTotalAverageCPCInWeeks(campaigns);
        state.user.marketingData.meta.campaigns.generalMetrics.cost_per_unique_click.totalDataInMonths = calculateTotalAverageCPCInMonths(campaigns);
        state.user.marketingData.meta.campaigns.generalMetrics.cost_per_unique_click.totalDataInYears = calculateTotalAverageCPCInYears(campaigns);
    },

    updateImpressionsGeneralMetric(state, generalMetrics) {
        state.user.marketingData.meta.campaigns.generalMetrics.impressions.totalImpressions = generalMetrics.totalImpressions;
    },

    updateFetchTracking(state, { platform, status }) {
        const currentTime = new Date().toISOString();
        state.user.marketingData[platform].lastFetchTime = currentTime;
        state.user.marketingData[platform].wasSuccessful = status;
      },
}