export default {
  mounted(el, binding) {
    const options = binding.value || {};
    const position = Object.keys(binding.modifiers)[0] || 'top';

    // Predefined size presets
    const sizePresets = {
      small: { padding: '4px 8px', fontSize: '10px' },
      medium: { padding: '6px 10px', fontSize: '12px' },
      large: { padding: '8px 14px', fontSize: '14px' },
    };

    // Predefined theme presets
    const themePresets = {
      dark: { backgroundColor: '#333', color: '#fff' },
      light: { backgroundColor: '#fff', color: '#000', border: '1px solid #ccc' },
    };

    const size = options.size || 'medium';
    const theme = options.theme || 'light'; // Default to 'dark'

    const tooltipStyles = {
      ...themePresets[theme], // Apply theme styles
      ...sizePresets[size],   // Apply size styles
      borderRadius: options.borderRadius || '4px',
      whiteSpace: options.whiteSpace || 'nowrap',
      zIndex: options.zIndex || 1000,
      transition: 'opacity 0.2s',
      position: 'absolute',
      pointerEvents: 'none',
      visibility: 'hidden',
      ...options.styles, // Merge additional custom styles
    };

    // Create tooltip element
    const tooltip = document.createElement('div');
    tooltip.className = `tooltip tooltip-${position}`;
    tooltip.innerText = options.text || '';
    Object.assign(tooltip.style, tooltipStyles);

    document.body.appendChild(tooltip);

    const positionTooltip = () => {
      const rect = el.getBoundingClientRect();
      const tooltipRect = tooltip.getBoundingClientRect();

      switch (position) {
        case 'top':
          tooltip.style.left = `${rect.left + rect.width / 2 - tooltipRect.width / 2}px`;
          tooltip.style.top = `${rect.top - tooltipRect.height - 6}px`;
          break;
        case 'bottom':
          tooltip.style.left = `${rect.left + rect.width / 2 - tooltipRect.width / 2}px`;
          tooltip.style.top = `${rect.bottom + 6}px`;
          break;
        case 'left':
          tooltip.style.left = `${rect.left - tooltipRect.width - 6}px`;
          tooltip.style.top = `${rect.top + rect.height / 2 - tooltipRect.height / 2}px`;
          break;
        case 'right':
          tooltip.style.left = `${rect.right + 6}px`;
          tooltip.style.top = `${rect.top + rect.height / 2 - tooltipRect.height / 2}px`;
          break;
      }
    };

    // Show tooltip
    el.addEventListener('mouseenter', () => {
      positionTooltip();
      tooltip.style.visibility = 'visible';
      tooltip.style.opacity = '1';
    });

    // Hide tooltip
    el.addEventListener('mouseleave', () => {
      tooltip.style.visibility = 'hidden';
      tooltip.style.opacity = '0';
    });

    // Cleanup tooltip on unmount
    el._tooltipInstance = tooltip;
  },
  unmounted(el) {
    if (el._tooltipInstance) {
      document.body.removeChild(el._tooltipInstance);
      delete el._tooltipInstance;
    }
  },
};
