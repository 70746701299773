// Interfaces
import { IPartnershipRequest } from '@/datastructures/interfaces/users/IPartnershipRequest';
import { IBusinessPartner } from '@/datastructures/interfaces/users/IBusinessPartner';

interface State {
  user: {
    business_partner_requests: IPartnershipRequest[];
    business_partners: IBusinessPartner[];
  };
  businessDataOnBehalfOf_Email: string | null;
  businessDataOnBehalfOf_Name: string | null;
  businessDataOnBehalfOf_UUID: string | null;
}

export default {
  addBusinessPartnerRequest(state: State, partnershipRequest: IPartnershipRequest): void {
    state.user.business_partner_requests.push({
      email: partnershipRequest.email,
      userUUID: partnershipRequest.userUUID,
      status: partnershipRequest.status,
      userType: partnershipRequest.userType,
      profileImageUrl: partnershipRequest.profileImageUrl,
    });
  },

  removeBusinessPartnerRequest(state: State, receivingUserUUID: string): void {
    const index = state.user.business_partner_requests.findIndex((request) => {
      return request.userUUID === receivingUserUUID;
    });

    if (index !== -1) {
      console.log('Removing request at index:', index);
      state.user.business_partner_requests.splice(index, 1);
    } else {
      console.log('No request found with the given receivingUserUUID');
    }
  },

  addBusinessPartner(state: State, businessPartner: IBusinessPartner): void {
    state.user.business_partners.push(businessPartner);
  },

  removeBusinessPartner(state: State, partnerUUID: string): void {
    state.user.business_partners = state.user.business_partners.filter((partner) => partner.userUUID !== partnerUUID);
    state.businessDataOnBehalfOf_Email = null;
    state.businessDataOnBehalfOf_Name = null;
  },

  businessDataOnBehalfOf_UUID(state: State, partnerUUID: string): void {
    state.businessDataOnBehalfOf_UUID = partnerUUID;
  },
};
