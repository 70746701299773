import axios from '../../axios-auth'
import { ActionContext } from 'vuex';
import { State, ContactsState, Contact } from '@/types';

// Interfaces
import { IContactObject } from '@/types/contacts/Contact';

// Define the mutations interface
interface Mutations {
  storeContacts: (state: ContactsState, payload: Contact[]) => void;
  storeContact: (state: ContactsState, payload: Contact) => void;
  addContact: (state: ContactsState, payload: Contact) => void;
  updateContact: (state: ContactsState, payload: Contact) => void;
  removeContact: (state: ContactsState, payload: string) => void;
}

// Type for the Action Context
type ContactsActionContext = ActionContext<ContactsState, State> & {
  commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
};

const routePath = '/contacts';

export default {
  // Fetch all contacts
  async fetchContacts({ commit }: ContactsActionContext): Promise<Contact[]> {
    try {
      const response = await axios.get<{ success: boolean; data: IContactObject[] }>(routePath);
      const contacts = response.data.data; // Extract contacts array from the response
      
      commit('storeContacts', contacts);

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Fetch a single contact by UUID
  async fetchContactById({ commit }: ContactsActionContext, uuid: string): Promise<Contact> {
    try {
      const response = await axios.get<{ success: boolean; data: IContactObject }>(`${routePath}/${uuid}`);
      const contact = response.data.data; // Extract contact object from response

      commit('storeContact', contact);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Create a new contact
  async createContact({ commit }: ContactsActionContext, contactData: Contact): Promise<Contact> {
    try {
      const response = await axios.post<Contact>(routePath, contactData);

      const allContacts = response.data.data;
      
      commit('addContact', allContacts);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Update a contact by UUID
  async updateContact(
    { commit }: ContactsActionContext,
    { uuid, contactData }: { uuid: string; contactData: Partial<Contact> }
  ): Promise<Contact> {
    try {
      const response = await axios.put<Contact>(`${routePath}/${uuid}`, contactData);
      commit('updateContact', response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Patch a contact by UUID (partial update)
  async patchContact(
    { commit }: ContactsActionContext,
    { uuid, patchData }: { uuid: string; patchData: Partial<Contact> }
  ): Promise<Contact> {
    try {

      console.log('ACTION - patchdata: ', patchData.companyName);
      console.log('ACTION - uuid: ', uuid);

      const response = await axios.patch<Contact>(`${routePath}/${uuid}`, patchData);
      const updatedContact = response.data.data;

      commit('updateContact', updatedContact); // Using the same mutation as update
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Delete a contact by UUID
  async deleteContact({ commit }: ContactsActionContext, uuid: string): Promise<string> {
    try {
      await axios.delete(`${routePath}/${uuid}`);
      commit('removeContact', uuid);
      return uuid;
    } catch (error) {
      throw error;
    }
  },
};
