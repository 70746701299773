// Service
import { LocalStorageService } from '@/service/localStorage/LocalStorageService';
import log from 'loglevel';

// Interfaces
import { IMetaPage } from '@/datastructures';

// Initialize service
const localStorageService = new LocalStorageService();

interface FacebookAuthResponse {
  accessToken: string;
  data_access_expiration_time: number;
  expiresIn: number;
  graphDomain: string;
  signedRequest: string;
  userID: string;
}

interface FacebookUserAuth {
  platformCredentials: {
    meta: {
      accessToken: string;
      dataAccessExpirationTime: number;
      expiresIn: number;
      graphDomain: string;
      signedRequest: string;
      userID: string;
      pages: [
        {
          id: String | null; // Facebook Page ID
          name: String | null; // Facebook Page Name
          category: String | null; // Page Category
          instagram: {
            id: String | null; // Instagram Business Account ID (retrieved later)
            username: String | null; // Instagram Username (retrieved later)
          };
        }
      ];
    };
  };
}

interface MetaConnectionResult {
  success: boolean;
  message: string;
}

export class MetaService {
  triggerMetaConnection(store: any): Promise<MetaConnectionResult> {
    console.log('Triggering Facebook login from MetaService.ts ...');

    // console.log("Facebook App ID:", import.meta.env.VITE_FACEBOOK_APP_ID);
    console.log('Meta Config ID:', import.meta.env.VITE_META_CONFIG_ID);

    return new Promise((resolve, reject) => {
      if (!window.FB) {
        return resolve({
          success: false,
          message: 'Facebook SDK not loaded.',
        });
      }

      FB.login(
        function (response: any) {
          console.log('Facebook login response:', response);

          if (response.status === 'connected') {
            const authResponse: FacebookAuthResponse = response.authResponse;
            console.log('Facebook auth response:', authResponse);
            const facebookUserAuth: FacebookUserAuth = {
              platformCredentials: {
                meta: {
                  accessToken: authResponse.accessToken,
                  dataAccessExpirationTime: authResponse.data_access_expiration_time,
                  expiresIn: authResponse.expiresIn,
                  graphDomain: authResponse.graphDomain,
                  signedRequest: authResponse.signedRequest,
                  userID: authResponse.userID,
                  pages: [
                    {
                      id: null,
                      name: null,
                      category: null,
                      instagram: {
                        id: null,
                        username: null,
                      },
                    },
                  ],
                },
              },
            };

            store
              .dispatch('initMetaUser', facebookUserAuth)
              .then((res: any) => {
                const pages = res.data.data.platformCredentials.meta.data.pages.data;
                const pageWithInstagram = pages.find((page: any) => page.instagram_business_account && page.instagram_business_account.id);

                if (pageWithInstagram) {
                  localStorageService.storeItemAsJSON({ itemName: 'meta-selectedPage', item: pageWithInstagram });
                }

                resolve({ success: true, message: 'You have successfully connected your Facebook account.' });
              })
              .catch((error: any) => {
                console.error('Error handling Facebook data: ', error);
                resolve({
                  success: false,
                  message: 'Failed to connect your Facebook account.',
                });
              });
          } else {
            resolve({
              success: false,
              message: 'User cancelled login or did not fully authorize.',
            });
          }
        },
        {
          scope: 'public_profile,email',
          config_id: import.meta.env.VITE_META_CONFIG_ID,
        }
      );
    });
  }

  // TODO just storing
  //          scope: "public_profile,email,instagram_basic,instagram_manage_insights,pages_read_engagement,pages_show_list,ads_read",

  // TODO: Implemtent this
  getSelectedFacebookPage(facebookPagesList: IMetaPage[]) {
    // 1. Retrieve stored page from localStorage
    const storedPage = localStorageService.getItemAsJSON('meta-selectedPage');
  
    // 2. If stored page exists, return it
    if (storedPage) {
      return storedPage;
    }
  
    // 3. Retrieve Facebook pages from state using the getter
    // const facebookPagesList = store.getters.getMetaPatePlatformCredentialsData;
  
    // 4. Check if we have pages available and set the first page as the selected one
    if (facebookPagesList && facebookPagesList.length > 0) {
      const firstPage = facebookPagesList[0];
      localStorageService.setItemAsJSON('meta-selectedPage', firstPage);
      return firstPage;
    }
    
    // 5. Return null if no page is available
    return null;
  }
  

  getMetaPageSelection(): any {
    const storedPage = localStorageService.getItemAsJSON('meta-selectedPage');
    if (storedPage) {
      return storedPage;
    }
    return null;
  }

  setMetaPageSelection(page: any) {
    if (page && page.instagram_business_account && page.instagram_business_account.id) {
      localStorageService.setItemAsJSON('meta-selectedPage', page);
      console.log('Page selection set in local storage:', page);
    }
  }
}
