import { PartnershipRequestTypeEnum } from '@/datastructures/enums/websockets/partnershipRequest/PartnershipRequestTypeEnum';

export function handlePartnershipRequest(partnershipRequest: any, commit: any) {
  switch (partnershipRequest.type) {
    case PartnershipRequestTypeEnum.ADD:
      commit('addBusinessPartnerRequest', partnershipRequest);
      break;

    case PartnershipRequestTypeEnum.REMOVE:
      commit('removeBusinessPartnerRequest', partnershipRequest.userUUID);
      break;

    case PartnershipRequestTypeEnum.ACCEPT:
      commit('addBusinessPartner', partnershipRequest);
      break;

    case PartnershipRequestTypeEnum.REMOVE_PARTNERSHIP:
      commit('removeBusinessPartner', partnershipRequest.userUUID);
      break;
  }
}
