  <script setup lang="ts">
  import { ref, computed, watch, defineAsyncComponent, onMounted, onUnmounted } from "vue";
  import { useStore } from "vuex";
  import { useI18n } from "vue-i18n";
  import { useLocaleMessages } from "@/utils/locales/useLocaleMessages";

  // Interfaces
  import { IContactObject } from "@/types/contacts/Contact";
  import { IMediaLink } from "@/datastructures/interfaces/contacts/IMediaLink"

  // Initializations
  const store = useStore();

  // Components
  const ContactGeneralView = defineAsyncComponent(() => import('./ContactGeneralView.vue'));
  import ContactLinkView from './ContactLinkView.vue';
  import ContactReminderView from './components/ContactReminderView.vue';

  // Props
  const props = defineProps<{
    contact: Record<string, any> | null;
    show: boolean;
  }>();

  const emit = defineEmits(["close", "patch", "delete"]);

  // Tabs array
  const tabs = computed(() => [
    { label: 'General', icon: 'pi pi-fw pi-user' },
    { label: 'Links', icon: 'pi pi-fw pi-link' },
    { label: 'Notifications', icon: 'pi pi-fw pi-bell' },
  ]);

  // Refs
  const activeTab = ref(0);
  const editing = ref(false);
  const contactImageFileInput = ref(null);
  const showDeleteConfirmation = ref(false);
  const editableContact = ref({ ...props.contact });

  // Translations
  const { t } = useI18n();
  useLocaleMessages({
    da: () => import("@/locales/da/views/contacts/contactModal.json"),
    en: () => import("@/locales/en/views/contacts/contactModal.json"),
  });

  // Watchers
  watch(
    () => props.contact,
    (newVal) => {
      if (newVal) {
        editableContact.value = { ...newVal };
      }
    },
    { immediate: true, deep: true }
  );
  watch(
    () =>
      store.state.contacts.find((contact) => contact.uuid === props.contact?.uuid)
        ?.contactImageUrl,
    (newImage) => {
      if (newImage) {
        editableContact.value.contactImageUrl = newImage;
      }
    }
  );

  // Computed property to control Dialog visibility
  const showDialog = computed({
    get: () => props.show,
    set: (value) => {
      if (!value) emitClose();
    },
  });

  // Functions
  const toggleEditing = () => {
    if (activeTab.value === 1) {
      // Ensure links are synchronized before saving
      updateLinks(editableContact.value.mediaLinks);
    }
    if (editing.value) {
      emit("patch", editableContact.value);
    }
    editing.value = !editing.value;
  };

  function updateContact(updatedData: Partial<IContactObject>) {
    editableContact.value = {
      ...editableContact.value,
      ...updatedData,
    };

    console.log("Updated contact:", editableContact.value);

    emit("patch", editableContact.value);
  };

  // Emit delete event with UUID to the parent component
  const deleteContact = () => {
    if (showDeleteConfirmation.value) {
      if (editableContact.value.uuid) {
        emit("delete", editableContact.value.uuid);
        showDeleteConfirmation.value = false; // Reset the confirmation state after deletion
      }
    } else {
      showDeleteConfirmation.value = true; // Show confirmation buttons
    }
  };

  // Trigger the file input click
  const clickFileInput = () => {
    contactImageFileInput.value.click();
  };

  // Handle file input change
  const handleFilesInput = () => {
    const files = contactImageFileInput.value.files;
    if (files && files.length > 0) {
      processContactImageUpload(files[0]);
    }
  };

  // Process contact image upload
  const processContactImageUpload = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    store
      .dispatch("uploadContactImage", {
        uuid: editableContact.value.uuid,
        formData,
      })
      .then(() => {
        console.log("Contact image uploaded successfully");
      })
      .catch((error) => {
        console.error("Error uploading contact image:", error);
      });
  };

  function emitClose() {
    emit("patch", editableContact.value);
    emit("close");
  }

  const updateLinks = (updatedLinks: IMediaLink[]) => {
    editableContact.value.mediaLinks = updatedLinks; // Update mediaLinks in the editable contact
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    const activeElement = document.activeElement as HTMLElement | null;

    if (
      activeElement?.tagName === "INPUT" ||
      activeElement?.tagName === "TEXTAREA" ||
      activeElement?.isContentEditable
    ) {
      return;
    }

    if (event.key === "ArrowRight") {
      event.preventDefault();
      activeTab.value = (activeTab.value + 1) % tabs.value.length;
    } else if (event.key === "ArrowLeft") {
      event.preventDefault();
      activeTab.value = (activeTab.value - 1 + tabs.value.length) % tabs.value.length;
    }
  };

  onMounted(() => {
    window.addEventListener("keydown", handleKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener("keydown", handleKeyDown);
  });
</script>
  
<template>
  <Dialog v-model:visible="showDialog" modal closeOnEscape dismissableMask :closable="true" :maximizable="true"
    :maximized="false" class="custom-dialog" :contentStyle="{ height: '80vh', overflow: 'hidden' }"
    :style="{ width: '90%' }" :showHeader="true" position="center" :draggable="false">
    <template #header>
      <div class="text-3xl ml-4"></div>
    </template>

    <div class="modal-container">
      <!-- Modal Header -->
      <div class="modal-header">
        <div class="profile-image-container">
          <input type="file" ref="contactImageFileInput" @change="handleFilesInput" hidden />
          <div class="image-container">
            <img :src="editableContact.contactImageUrl || '/images/icons/icons8-male-user-50-gray.png'"
              alt="Contact Image" class="profile-image" />
          </div>
          <div @click="clickFileInput" class="profile-image-overlay">
            <span class="edit-text">Edit</span>
          </div>
        </div>
        <span class="company-name">{{ contact.companyName }}</span>
      </div>

      <!-- Tab Navigation -->
      <div class="surface-section px-2">
        <TabMenu :model="tabs" :activeIndex="activeTab" @tabChange="(e) => activeTab = e.index"></TabMenu>
      </div>

      <!-- Modal Body -->
      <div class="modal-body">
        <ContactGeneralView v-if="activeTab === 0" :contact="editableContact" :editing="editing"
          @update:contact="(updatedContact) => (editableContact = updatedContact)" />
        <ContactLinkView v-else-if="activeTab === 1" :links="editableContact.mediaLinks" :editing="editing"
          @update:links="updateLinks" />
        <ContactReminderView :toggles="editableContact.toggles" :editing="editing" @update:contact="updateContact"
          v-else />
      </div>

      <!-- Footer -->
      <div class="modal-footer">
        <template v-if="editing">
          <template v-if="showDeleteConfirmation">
            <p class="confirmation-text">{{ t('contactModal.confirmDeleteText') }}</p>
            <div class="flex gap-2">
              <Button id="contact-modal-confirm-delete" :label="t('contactModal.button.yes')" severity="danger" text
                @click="deleteContact" />
              <Button id="contact-modal-cancel-delete" :label="t('contactModal.button.no')" severity="secondary" text
                @click="() => (showDeleteConfirmation = false)" />
            </div>
          </template>
          <template v-else>
            <Button id="contact-modal-delete" :label="t('contactModal.button.delete')" severity="danger" text
              class="mr-2" @click="() => (showDeleteConfirmation = true)" />
            <Button id="contact-modal-cancel" :label="t('contactModal.button.save')" severity="secondary" text
              @click="toggleEditing" />
          </template>
        </template>
        <template v-else>
          <Button id="contact-modal-edit" :label="t('contactModal.button.edit')" severity="secondary" text
            @click="toggleEditing" />
        </template>
      </div>

    </div>
  </Dialog>
</template>


<style scoped>
.confirmation-text {
  font-weight: bold;
  color: var(--text-color-secondary);
  margin-bottom: 0.5rem;
  text-align: center;
}

.flex.gap-2>* {
  margin-left: 0.5rem;
}

.modal-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.modal-information-section {
  margin-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.modal-body {
  padding: 2rem;
}

.custom-dialog {
  padding: 2rem;
}

/* Divider Style */
.divider {
  margin: 0 auto;
  /* Center the divider */
  border: none;
  border-top: 1px solid #ccc;
  /* Create the horizontal line */
  margin: 1rem;
}

.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 8px;
  object-fit: cover;
  /* Maintain the aspect ratio and cover the container */
  border: 1px solid #d1d1d1;
  /* Add a light gray border around the image */
}

.company-name {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  margin-left: 1rem;
  text-align: left;
}

.modal-content {
  padding: 20px;
}

/* Profile Image Container */
.profile-image-container {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  display: inline-block;
}

.image-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
}

.profile-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 50%;
}

.profile-image-overlay:hover {
  opacity: 1;
}

.edit-text {
  color: white;
  font-size: 1rem;
  font-weight: lighter;
}

.profile-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.modal-body-expanded {
  display: flex;
  height: 100%;
  overflow: hidden;
  /* Prevent overflow issues */
}

.notes-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.notes-textarea {
  flex-grow: 1;
  /* Let the textarea grow to fill available space */
  min-height: 12rem;
}

.modal-header {
  padding: 10px;
}

.modal-body {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
}
</style>