<script setup lang="ts">
import { defineProps, defineEmits } from "vue";

// Props
const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true,
    },
    label: {
        type: String,
        default: "",
    },
    id: {
        type: String,
        required: true,
    },
});

// Emits
const emit = defineEmits(["update:modelValue"]);

// Methods
const toggleCheckbox = () => {
    emit("update:modelValue", !props.modelValue);
};
</script>

<template>
    <div class="mm-checkbox-wrapper" @click="toggleCheckbox">
        <div class="mm-checkbox" :class="{ checked: props.modelValue }"></div>
        <label :for="id" class="ml-3">{{ label }}</label>
    </div>
</template>

<style scoped>
.mm-checkbox-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

.mm-checkbox {
    width: 18px; /* Increased size */
    height: 18px; /* Increased size */
    border: 2px solid #ccc; /* Adjusted for larger size */
    border-radius: 4px; /* Slightly rounded */
    display: inline-block;
    position: relative;
    background-color: white;
    transition: background-color 0.2s, border-color 0.2s;
}

.mm-checkbox.checked {
    background-color: #007bff;
    border-color: #007bff;
}

.mm-checkbox.checked::after {
    content: "";
    position: absolute;
    bottom: 4px;
    left: 4px; /* Adjusted for larger size */
    width: 6px; /* Adjusted for larger size */
    height: 10px; /* Adjusted for larger size */
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
</style>
