import { createRouter, createWebHistory } from 'vue-router';
import AppLayout from '@/layout/AppLayout.vue';
import i18n from '@/utils/locales/i18n';

async function loadRouteMessages(locale) {
  try {
    if (i18n.global.availableLocales.includes(locale)) return;
    const messages = await import(`@/locales/${locale}/router/index.json`);
    i18n.global.setLocaleMessage(locale, messages.default);
  } catch (error) {
    console.error(`Failed to load locale messages for ${locale}:`, error);
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'application',
      component: AppLayout,
      children: [
        {
          path: '/',
          name: 'overview',
          meta: {
            requiresAuth: true,
            breadcrumb: ['router.application.overview.breadcrumb'],
          },
          component: () => import('@/views/dashboard/Overview.vue'),
        },
        {
          path: '/profile',
          name: 'profile',
          meta: {
            requiresAuth: true,
            breadcrumb: ['router.application.profile.breadcrumb'],
          },
          component: () => import('@/views/profile/Profile.vue'),
        },
        {
          path: '/profile/applyPartnership',
          name: 'applyPartnership',
          meta: {
            requiresAuth: true,
            breadcrumb: [
              'router.application.profile.applyPartnership.breadcrumb',
              'router.application.profile.applyPartnership.request',
            ],
          },
          component: () => import('@/views/profile/ProfileApplyPartnership.vue'),
        },
        {
          path: '/profile/list',
          name: 'user-list',
          meta: {
            requiresAuth: true,
            breadcrumb: ['router.application.profile.userManagement', 'router.application.profile.userList'],
          },
          component: () => import('@/views/user-management/UserList.vue'),
        },
        {
          path: '/business-partners',
          name: 'business-partners',
          meta: {
            requiresAuth: true,
            breadcrumb: ['router.application.businessPartners.breadcrumb'],
          },
          component: () => import('@/views/business-partners/BusinessPartners.vue'),
        },
        {
          path: '/contacts',
          name: 'contacts',
          meta: {
            requiresAuth: true,
            breadcrumb: ['router.application.customerNotes.breadcrumb'],
          },
          component: () => import('@/views/contacts/Contacts.vue'),
        },
        {
          path: '/reports',
          name: 'reports',
          meta: {
            requiresAuth: true,
            breadcrumb: ['router.application.reports.breadcrumb'],
          },
          component: () => import('@/views/reports/Reports.vue'),
        },
        {
          path: '/messages',
          meta: {
            requiresAuth: true,
            breadcrumb: ['router.application.messages.breadcrumb'],
          },
          component: () => import('@/views/messages/Index.vue'),
          children: [
            {
              path: '/messages/inbox',
              name: 'message-inbox',
              meta: {
                breadcrumb: ['router.application.messages.inbox'],
              },
              component: () => import('@/views/messages/MessageDataTable.vue'),
            },
            {
              path: '/messages/compose',
              name: 'message-compose',
              meta: {
                breadcrumb: ['router.application.messages.compose'],
              },
              component: () => import('@/views/messages/ComposeNew.vue'),
            },
            {
              path: '/messages/detail/:uuid',
              name: 'message-detail',
              meta: {
                breadcrumb: ['router.application.messages.detail'],
              },
              component: () => import('@/views/messages/MessageDetails.vue'),
            },
            {
              path: '/messages/sent',
              name: 'message-sent',
              component: () => import('@/views/messages/MessageDataTable.vue'),
            },
          ],
        },
        {
          path: '/membershipplans',
          name: 'membership-plans',
          meta: {
            requiresAuth: true,
            breadcrumb: ['router.application.membershipPlans.breadcrumb'],
          },
          component: () => import('@/views/payment/MembershipPlans.vue'),
        },
        {
          path: '/meta-checklist',
          name: 'meta-checklist',
          meta: {
            requiresAuth: true,
            breadcrumb: ['router.application.metaChecklist.breadcrumb'],
          },
          component: () => import('@/views/checklists/MetaChecklist.vue'),
        },
        {
          path: '/files',
          name: 'files',
          meta: {
            requiresAuth: true,
            breadcrumb: ['router.application.files.breadcrumb'],
          },
          component: () => import('@/views/apps/Files.vue'),
        },
        {
          path: '/Contentkalender',
          name: 'contentkalender',
          meta: {
            requiresAuth: true,
            breadcrumb: ['router.application.contentCalendar.breadcrumb'],
          },
          component: () => import('@/views/pages/content-calendar/ContentCalendar.vue'),
        },
        {
          path: '/marketing-academy',
          name: 'marketing-academy',
          meta: {
            requiresAuth: true,
            breadcrumb: ['router.application.marketingAcademy.breadcrumb'],
          },
          component: () => import('@/views/marketing-academy/VideoCoursesLandingpage.vue'),
        },
        {
          path: '/marketing-academy/meta-fundamentals',
          name: 'meta-fundamentals',
          meta: {
            requiresAuth: true,
            breadcrumb: [
              'router.application.marketingAcademy.breadcrumb',
              'router.application.metaFundamentals.breadcrumb',
            ],
          },
          component: () => import('@/views/marketing-academy/meta-fundamentals/MetaFundamentalsCourse.vue'),
        },

        // Meta Ads
        {
          path: '/meta',
          name: 'Meta',
          meta: {
            requiresAuth: true,
            breadcrumb: ['router.application.meta.breadcrumb'],
          },
          component: () => import('@/views/meta/MetaOverview.vue'),
        },

        // Google Ads
        {
          path: '/google-ads',
          name: 'Google Ads',
          meta: {
            requiresAuth: true,
            breadcrumb: ['router.application.googleAds.breadcrumb'],
          },
          component: () => import('@/views/google/GoogleAdsOverview.vue'),
        },
      ],
    },
    {
      path: '/auth/signin',
      name: 'signin',
      component: () => import('@/views/pages/auth/signin/Signin.vue'),
    },
    {
      path: '/auth/signup',
      name: 'signup',
      component: () => import('@/views/pages/auth/signup/Signup.vue'),
    },
    {
      path: '/auth/forgotpassword',
      name: 'forgotpassword',
      component: () => import('@/views/pages/auth/ForgotPassword.vue'),
    },
    {
      path: '/auth/activateAccount',
      name: 'activateAccount',
      component: () => import('@/views/pages/auth/email_components/ActivateAccount.vue'),
    },
    {
      path: '/auth/changeEmail',
      name: 'changeEmail',
      component: () => import('@/views/pages/auth/email_components/ChangedEmail.vue'),
    },
    {
      path: '/auth/newpassword',
      name: 'newpassword',
      component: () => import('@/views/pages/auth/email_components/NewPassword.vue'),
    },
    {
      path: '/auth/verification',
      name: 'verification',
      component: () => import('@/views/google/GoogleAdsOverview.vue'),
    },

    // Public routes
    {
      path: '/resourcer',
      name: 'resourcer',
      component: () => import('@/views/public/Resources.vue'),
      props: (route) => ({
        slug: route.params.slug,
        lang: localStorage.getItem('selectedLanguage') || 'da',
      }),
    },
    {
      path: '/resourcer/:slug',
      name: 'Article',
      component: () => import('@/views/public/views/resources/articles/Article.vue'),
      props: (route) => ({
        slug: route.params.slug,
        lang: localStorage.getItem('selectedLanguage') || 'da',
      }),
    },
    // Contact
    {
      path: '/kontakt',
      name: 'kontakt',
      component: () => import('@/views/public/contact/Contact.vue'),
    },
    // Legal related
    {
      path: '/privacypolicy',
      name: 'privacypolicy',
      component: () => import('@/views/pages/legal/privacypolicy/Layout.vue'),
    },
    {
      path: '/termsandconditions',
      name: 'termsandconditions',
      component: () => import('@/views/pages/legal/termsandconditions/Layout.vue'),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  let locale = i18n.global.locale || localStorage.getItem('selectedLanguage') || 'da';

  if (!i18n.global.availableLocales.includes(locale)) {
    await loadRouteMessages(locale);
  }

  if (to.meta.breadcrumb && Array.isArray(to.meta.breadcrumb)) {
    to.meta.breadcrumb = to.meta.breadcrumb.map((crumb) => i18n.global.t(crumb));
  }

  // const token = localStorage.getItem('token');
  // const expirationDate = localStorage.getItem('expirationDate');
  // const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  // Public Routes
  const isSignup = to.path === '/auth/signup';
  const isForgotPassword = to.path === '/auth/forgotpassword';
  const isPrivatePolicy = to.path === '/privacypolicy';
  const isTermsAndConditions = to.path === '/termsandconditions';
  const isContact = to.path === '/kontakt';

  // Administration
  const isNewPassword = to.path === '/auth/newpassword' && 'token' in to.query;
  const isActivateAccount = to.path === '/auth/activateAccount' && 'token' in to.query;
  const isChangeEmail = to.path === '/auth/changeEmail' && 'token' in to.query;

  if (
    isSignup ||
    isForgotPassword ||
    isTermsAndConditions ||
    isPrivatePolicy ||
    isNewPassword ||
    isActivateAccount ||
    isChangeEmail
  ) {
    next();
    return;
  }

  next();
});

export default router;
