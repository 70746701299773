import axios from "../../axios-auth";

// Define the notification data structure
interface Notification {
  id: string;
  title: string;
  message: string;
  read: boolean;
  createdAt: string;
}

interface State {
  user: {
    uuid: string;
  };
}

interface Context {
  state: State;
  commit: (mutation: string, payload?: any) => void;
}

// Storage route path
const routePath = "/notifications";

export default {
  async getNotifications({ state, commit }: Context): Promise<void> {
    try {
      const userUUID = state.user.uuid;
      const res = await axios.get<{ data: Notification[] }>(
        `${routePath}/${userUUID}`
      );
      commit("storeNotifications", res.data.data);
    } catch (error) {
      console.error("Failed to fetch notifications:", error);
    }
  },

  async markAllNotificationsAsRead({ state, commit }: Context): Promise<void> {
    try {
      const userUUID = state.user.uuid;
      await axios.patch(`${routePath}/markAllRead/${userUUID}`);
      commit("markAllNotificationsAsReadLocally");
    } catch (error) {
      console.error("Failed to mark notifications as read:", error);
    }
  },

  async clearNotifications({ state, commit }: Context): Promise<void> {
    try {
      await axios.delete(`${routePath}/${state.user.uuid}`);
      commit("clearNotificationsLocally");
    } catch (error) {
      console.error("Failed to clear notifications:", error);
    }
  }
};
